import { TweenMax,Power4, TimelineMax } from 'gsap';
import verge from 'verge';

export default function(){
	const button = document.querySelector('.js-back')
	const img = document.querySelector('.header-container-hero').getBoundingClientRect()
	const scroll = verge.scrollY()
	const condition = img.y < 0

	if ( condition ) {
		button.classList.add("fixed")
		} else {
		button.classList.remove("fixed")
	}
	button.addEventListener('click', () => {
		window.scrollTo({
  			top: 0,
  			behavior: 'smooth',
		})
	})
}