import TweenMax from 'gsap';
import verge from 'verge';

export default function() {
	this.init = function(el){
		this.speed = el.getAttribute('data-speed')
		this.el = el.getBoundingClientRect()
		if ( el.getAttribute('data-el') ) {
			this.el = document.querySelector(el.getAttribute('data-el')).getBoundingClientRect()
		} 
		this.min = window.pageYOffset + this.el.top - window.innerHeight
		this.max = window.pageYOffset + this.el.top + this.el.height
		this.scroll = verge.scrollY()
		this.height = el.offsetHeight
		this.animate(el)
	}
	this.map = function(value, low1, high1, low2, high2) {
		return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
	}
	this.animate = function(elem){

			let max = this.max
			let min = this.min
			let amount = 0
			if ( window.innerWidth < window.innerHeight ) {
				amount = 30
			} else {
				amount = 40
			}
			let val = this.map(this.scroll,min,max,-amount,amount)

			let tween = val * this.speed

			TweenMax.to(elem,.4,{
				y : -(tween)
			})
		
		}		
}



	  	