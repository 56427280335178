import { TweenMax,Power4, TimelineMax } from 'gsap';
import verge from 'verge';

export default function(){
	const button = document.querySelector('.js-info-button')
	const img = document.querySelector('.header-container-hero').getBoundingClientRect()
	const header = document.querySelector('.header-container-logo').getBoundingClientRect()
	const scroll = verge.scrollY()

	const condition_1 = scroll < header.height
	const condition_2 = scroll > header.height
	const condition_3 = ( img.top + img.height ) < 0

	if ( condition_1 ) {
		button.classList.remove("fixed")
		button.classList.remove("right")
	} else if ( condition_2 && !condition_3 ) {	
		button.classList.add("right")
	} else {
		
		button.classList.add("fixed")
		button.classList.remove("right")
	}

	
}
