import { TweenMax, TimelineMax, Expo, Power2 } from 'gsap'
import SplitText from './split'
import verge from 'verge';

export default function() {
	const els = Array.from(document.querySelectorAll('.scroll-letters'))
	els.forEach((el) => {
		if ( verge.inY(el,-200) && !el.animating) {
		      el.animating = true
			  const tl = new TimelineMax({
			  	delay: .4
			  })
			  const split = new SplitText(el, {type: 'words,chars'})

			  tl
			    .set(split.chars, {
			      autoAlpha: 0,
			      x: 16
			    })
			    .set(el, {
			      autoAlpha: 1
			    })
			    .staggerTo(split.chars, 1, {
			      autoAlpha: 1,
			      x: 0,
			      ease: Expo.easeOut
			    }, 0.02)
			    .add('finish')
			    .add(() => {split.revert()}, 'finish+=0.5')
		}
	})
}