import { TweenMax,Power4, TimelineMax } from 'gsap';
import verge from 'verge';
import InfoButton from './infoButton.js';
import BackToTop from './backtotop.js';
import Letters from './letter_animation.js';
import Parallax from './parallax.js';
import _ from 'lodash';

export default function() {
	const els = Array.from(document.querySelectorAll('.scroll-reveal'))
	els.forEach((e) => {
		TweenMax.set(e,{
			opacity: 0,
			y: 20
		})
		e.animating = false
	})
	const els_txt = Array.from(document.querySelectorAll('.scroll-letters'))
	els_txt.forEach((el) => {
		TweenMax.set(el, {
		    autoAlpha: 0
		  })
	})
	window.addEventListener('scroll', _.debounce(updatePosition, 60))


	function updatePosition() {	
	    els.forEach((e) => {
	    	if ( verge.inY(e, -200) && e.animating == false ) {
	    		e.animating = true	
	    		TweenMax.to(e,1,{
					opacity: 1,
					y: 0,
					x:0,
					delay: .5
				})
	    	}
		})
		InfoButton()     
		Letters()
		BackToTop()
		
		const par_images = Array.from(document.querySelectorAll('.js-parallax'))
		par_images.forEach((el) => {
			let parallax = new Parallax()
			parallax.init(el)
		})		 
		
	};
}