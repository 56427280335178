import { TweenMax,TimelineMax } from 'gsap';
document.addEventListener('DOMContentLoaded',() => {
	const button = document.querySelector('.js-info-button')
	const menu = document.querySelector('.js-menu')
	const logo = document.querySelector('.menu-header')
	const menu_link = document.querySelector('.menu-content')
	const menu_cta = document.querySelector('.menu-cta')
	const exit = document.querySelectorAll('.js-exit')
	const html = document.querySelector('html')
	button.addEventListener('click', toggle_menu)
	function toggle_menu(){
		const tl = new TimelineMax()
		tl.set(button,{zIndex:0})
		  .set(logo,{opacity:0})
	  	  .set(menu_link,{opacity:0})
	  	  .set(menu_cta,{opacity:0})	  	  
		  .to(menu,.5,{x : "-100%",})
		  .to(logo,.5,{opacity:1,y:10})
		  .to(menu_link,.5,{opacity:1,y:10})	
		  .to(menu_cta,.5,{opacity:1,y:10})			    
		html.classList.add('hidden')
		exit.forEach((el) => {
			el.addEventListener('click',() => {
				tl.reverse()
				html.classList.remove('hidden')
			})				
		})
	
	}
})

