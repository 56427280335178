import { TweenMax,Power4, TimelineMax } from 'gsap';
import _ from 'lodash';

export default function(){
		this.setup = function(el){
			this.container = el.querySelector('.quote_carousel_container')
			this.slides    = Array.from(this.container.querySelectorAll('.quote_carousel_slide'))
			this.pagination = el.querySelector('.quote_carousel_pagination')
			this.width = this.slides[0].offsetWidth
			this.active = 0
			this.length = this.slides.length
			this.bubbles = []
			this.setClones()
			this.create_bubbles()
			this.auto_scroll = setInterval(() => {
				this.go_to(this.active + 1)
			},5000)
			window.addEventListener('resize',_.debounce(this.set_width.bind(this), 150))
		}

		this.setClones = function(){
			let clone = this.slides[0].cloneNode(true)
			this.container.appendChild(clone)
		}

		this.set_width = function(){
			this.width = this.slides[0].offsetWidth		
			this.animate()	
		}
		this.create_bubbles = function(){
			this.slides.forEach((e,i) => {
			let bubble = document.createElement("SPAN");
			bubble.classList.add("quote_bubble")
			if ( i == this.active ) {
				bubble.classList.add("active")
			}
			bubble.addEventListener("click",() => {
				this.go_to(i)
				clearInterval(this.auto_scroll)
			})
			this.bubbles.push(bubble)
			this.pagination.appendChild(bubble)
			})
		}

		this.go_to = function(e){
			if ( e == this.length ) {
				this.active = 0
			    this.animate(true)
			} else {
				this.active = e
			    this.animate()
			}	
		}

		this.setActiveBubble = function() {
			this.bubbles.forEach((bubble,index) => {
				if ( this.active == index ) {
					bubble.classList.add("active")
				} else{
					bubble.classList.remove("active")
				}
			})
		}

		this.animate = function(reset = false){
			if ( reset ) {
				TweenMax.to(this.container,1,{
					x: -(this.width * this.length + 1),
					ease: Power4.easeInOut,
					onComplete: () => {
						this.reset()
					}
				})	
				this.setActiveBubble()			
			} else {
				TweenMax.to(this.container,1,{
					x: -(this.width * this.active),
					ease: Power4.easeInOut
				})			
				this.setActiveBubble()	
			}
			
		}
		this.reset = function(){
			TweenMax.set(this.container,{
				x : 0
			})
			this.active = 0
		}
	}