// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../../scss/application.scss'
import { TweenMax,Power4 } from 'gsap';
import '../menu.js';
import Slider from '../carousels.js';	
import Scroll from '../scroll.js';



document.addEventListener("DOMContentLoaded",function(){
	const carousels = Array.from(document.querySelectorAll('.js-slider'))	
	carousels.forEach((carousel) => {
		let slider = new Slider()
		slider.setup(carousel)
	})
	Scroll()
	
	const loader = document.querySelector('.loader')
	if ( loader ) {
		TweenMax.to(loader,1,{
			opacity: 0,
			delay: .4
		})
		loader.classList.add("loaded")
	}
})
